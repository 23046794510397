import React, { useMemo } from "react";

import { useViewContext } from "~/state";

import { LeadContent } from "./_lib";

import { useLeadView } from "@api/leads";

import { LeadDetails, makeCardGridItems } from "@leadId/index";
import { ViewComponent, createLeadView } from "@leadId/view";
import {
    IncomeVerificationRequest,
    accountElement,
    loanBreakDown,
    riskElements,
    verifiedIncome,
    voidCheque,
} from "@leadId/data/attributes";


const IncomeVerificationTab: ViewComponent = (props) => {
    const { lead, loading } = useLeadView(props.leadId, "");
    const { state: { incomeVerified } } = useViewContext();

    const IncomeVerificationCards = useMemo(() => incomeVerified ? makeCardGridItems<any, LeadDetails>([
        verifiedIncome,
        accountElement,
        loanBreakDown,
        riskElements,
        voidCheque,
    ]) : makeCardGridItems<any, LeadDetails>([
        IncomeVerificationRequest,
    ]), [incomeVerified]);

    return (
        <LeadContent
            cards={IncomeVerificationCards}
            lead={lead}
            loading={loading}
        />
    );
};

export default createLeadView(IncomeVerificationTab, {});